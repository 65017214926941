import React from "react";
import * as SC from "./styles";
import { AppText, AppTitle } from "pages/outer/components";
import { Button } from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { BenefitInterface } from "../../types";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";

const BenefitCard: React.FC<BenefitInterface> = ({ image, title, text }) => {
  return (
    <SC.Card>
      <SC.Image>
        <img src={image} alt="" />
      </SC.Image>
      <AppTitle lvl={3} size={2}>
        {title}
      </AppTitle>
      <AppText>{text}</AppText>
      <SC.ButtonWrap>
        <Button type="outer-link" path={APP_ROUTES.registration} isBorder>
          {_t("get_started")}
        </Button>
      </SC.ButtonWrap>
    </SC.Card>
  );
};

export default BenefitCard;
