import React from "react";
import * as SC from "./styles";
import { contactsItems } from "./constants";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const ContactsWidget = () => {
  return (
    <SC.Contacts>
      {contactsItems.map((item, idx) => {
        const Icon = item.Icon;
        return (
          <SC.Item
            key={idx}
            href={_t(item.path)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon />
          </SC.Item>
        );
      })}
    </SC.Contacts>
  );
};

export default ContactsWidget;
