import { card_1, card_2, card_3, card_4 } from "./images";
import { APP_ROUTES } from "system/constants/constantsUrl";

export const mainMapCardsList = (t) => [
  {
    title: t("bettilt"),
    text: t("map_card_text_1"),
    buttonText: t("get_started"),
    buttonType: "outer-link",
    path: t("company_product_link"),
    image: card_1,
  },
  {
    title: t("news"),
    text: t("map_card_text_2"),
    buttonText: t("get_started"),
    image: card_2,
    path: APP_ROUTES.news.all,
  },
  {
    title: t("streamspace"),
    text: t("map_card_text_3"),
    buttonText: t("get_started"),
    image: card_3,
    path: "/article/streamspace",
  },
  {
    title: t("contacts"),
    text: t("map_card_text_4"),
    buttonText: t("get_started"),
    image: card_4,
    path: APP_ROUTES.contacts,
  },

  // {
  //   title: t("rent_app"),
  //   btnLabel: t("get_started"),
  //   backgroundImg: RentApp,
  //   linkTo: APP_ROUTES.news.show.replace(":slug", "rent-application"),
  // },
];
