import { Mail, Skype, TG } from "./images";

export const contactsItems = [
  {
    path: "social_tg",
    Icon: TG,
  },
  {
    path: "social_skype",
    Icon: Skype,
  },
  {
    path: "social_mail",
    Icon: Mail,
  },
];
