import React from "react";
import * as SC from "./styles";
import { Button } from "pages/outer/components";
import { APP_ROUTES } from "system/constants/constantsUrl";
import { _t } from "modules/Common/components/InjectIntlContext";

const AuthButtons: React.FC = () => {
  return (
    <SC.Wrap>
      <Button type="outer-link" path={APP_ROUTES.login.main}>
        {_t("sign_in")}
      </Button>{" "}
      <Button type="outer-link" path={APP_ROUTES.registration} isBorder>
        {_t("sign_up")}
      </Button>
    </SC.Wrap>
  );
};

export default AuthButtons;
