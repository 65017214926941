import React from "react";
import * as SC from "./styles";
import { HeaderMenu } from "layouts/outer/components";
import { LangDropdown, AuthButtons } from "pages/outer/components";
// import { isAuthorized } from "system/helpers/localstorageHelper";
import { CloseButton } from "./components";
import { MobileMenuInterface } from "./types";

const MobileMenu: React.FC<MobileMenuInterface> = ({ toggleMenu }) => {
  // const isLogged = isAuthorized();
  return (
    <SC.MobileMenu>
      <SC.MobileMenuContent>
        <CloseButton toggleMenu={toggleMenu} />
        <HeaderMenu />
        <LangDropdown />
        <AuthButtons />
      </SC.MobileMenuContent>
    </SC.MobileMenu>
  );
};

export default MobileMenu;
