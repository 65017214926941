import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./constants/constantsUrl";
import {
  CommissionsPage,
  Home,
  News,
  CommissionRevShare,
  CommissionCPA,
  CommissionSubAffiliate,
  CommissionHybrid,
  Article,
  Tournaments,
  Testimonials,
  Contacts,
  Terms,
  PostBackOptions,
  TournamentPage,
  FAQ,
} from "../pages/outer";

export default (
  <Switch>
    <Route
      path={APP_ROUTES.commissions.revshare}
      component={CommissionRevShare}
      strict
    />
    <Route path={APP_ROUTES.commissions.cpa} component={CommissionCPA} strict />
    <Route
      path={APP_ROUTES.commissions.subaffiliate}
      component={CommissionSubAffiliate}
      strict
    />
    <Route
      path={APP_ROUTES.commissions.hybrid}
      component={CommissionHybrid}
      strict
    />
    <Route
      path={APP_ROUTES.commissions.commission}
      component={CommissionsPage}
      strict
    />
    <Route path={APP_ROUTES.news.all} component={News} strict />
    <Route path={APP_ROUTES.news.article} component={Article} strict />
    <Route path={APP_ROUTES.tournaments.all} component={Tournaments} strict />
    <Route
      path={APP_ROUTES.tournaments.instance}
      component={TournamentPage}
      strict
    />
    <Route path={APP_ROUTES.testimonials} component={Testimonials} exact />
    <Route path={APP_ROUTES.contacts} component={Contacts} strict />
    <Route path={APP_ROUTES.terms} component={Terms} strict />
    <Route
      path={APP_ROUTES.postback_options}
      component={PostBackOptions}
      strict
    />
    <Route path={APP_ROUTES.faq} component={FAQ} exact />
    <Route path="*" component={Home} />
  </Switch>
);
