import { banner_1, banner_2, banner_3, banner_4 } from "./images";

export const bannerSlides = (_t: any) => [
  {
    title: _t("main_banner_1_title"),
    text: _t("main_banner_1_text"),
    bannerImage: banner_1,
  },
  {
    title: _t("hot100"),
    text: _t("hot100_text"),
    bannerImage: banner_2,
  },
  {
    title: _t("become_partner"),
    text: _t("become_partner_text"),
    bannerImage: banner_3,
  },
  {
    title: _t("speed_banner"),
    text: _t("speed_banner_text"),
    bannerImage: banner_4,
  },
];
export const sliderSettings = {
  dots: true,
  fade: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
};
