import React, { useEffect, useState } from "react";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import * as SC from "./styles";
import {
  AppText,
  AppTitle,
  Button,
  CommissionsGrid,
} from "pages/outer/components";
import { _t } from "modules/Common/components/InjectIntlContext";
import { mainImage } from "./images";
import { APP_ROUTES } from "system/constants/constantsUrl";
import { isWindowWidth } from "common/helpers";
import { mediaQueries } from "system/themes";

const CommissionsPage = () => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    const handleMobileVersion = () => {
      setIsMobileLayout(isWindowWidth(`<=${mediaQueries.laptopSmall}`));
    };
    handleMobileVersion();
    window.addEventListener("resize", handleMobileVersion);
    return () => window.removeEventListener("resize", handleMobileVersion);
  }, []);
  return (
    <DefaultPage>
      <Container>
        <SC.Wrap>
          <AppTitle lvl={1} size={1}>
            {_t("Commissions")}
          </AppTitle>
          <AppText size={1}>{_t("commissions_page_description")}</AppText>
          <Button path={APP_ROUTES.registration} type="outer-link">
            {_t("get_started")}
          </Button>
          {!isMobileLayout && (
            <SC.Image>
              <img src={mainImage} alt="" />
            </SC.Image>
          )}
        </SC.Wrap>
        <SC.GridWrap>
          <CommissionsGrid />
        </SC.GridWrap>
      </Container>
    </DefaultPage>
  );
};

export default CommissionsPage;
