import styled, { css } from "styled-components";
import { Title } from "pages/outer/components/AppTitle/styles";
import { Text } from "pages/outer/components/AppText/styles";

export const Slide = styled.div`
  padding-top: 160px;
  min-height: 620px;
  position: relative;

  @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
    padding-top: 45px;
    min-height: 500px;
  }
`;
export const SlideContent = styled.div`
  position: relative;
  z-index: 10;

  ${() => css`
    ${Title} {
      font-size: 62px;
      margin-bottom: 25px;
      max-width: 650px;
      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        max-width: 450px;
        font-size: 36px;
      }
    }

    ${Text} {
      font-size: 21px;
      max-width: 500px;
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 50px;

      @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
        font-size: 18px;
        max-width: 360px;
      }
    }
  `}
`;
export const SlideImage = styled.div`
  position: absolute;
  bottom: 0;
  right: -250px;
  @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
    right: -85px;
  }

  img {
    max-height: 620px;
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.mediaQueries.laptopSmall}) {
      max-height: 500px;
    }
    @media (max-width: ${(props) => props.theme.mediaQueries.mobile}) {
      max-height: 250px;
    }
  }
`;
