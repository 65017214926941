import React, { useEffect, useState } from "react";
import * as SC from "./styles";
import { DefaultPage } from "layouts/outer";
import { Container } from "layouts/outer/components";
import { AppTitle, Button } from "pages/outer/components";
import OptionsList from "./components/OptionsList/OptionsList";
import { _t } from "modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "system/constants/constantsUrl";
import { useHistory } from "react-router-dom";
import { isWindowWidth } from "common/helpers";
import { mediaQueries } from "system/themes";
import { AppTheme } from "../../AppTheme";

const CommissionPage = ({ image, title, optionsList }) => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const handleMobileVersion = () => {
      setIsMobileLayout(isWindowWidth(`<=${mediaQueries.laptopSmall}`));
    };
    handleMobileVersion();
    window.addEventListener("resize", handleMobileVersion);
    return () => window.removeEventListener("resize", handleMobileVersion);
  }, []);
  return (
    <AppTheme>
      <DefaultPage hasBackground={false}>
        <SC.Wrap>
          {!isMobileLayout && (
            <SC.Image>
              <img src={image} alt="" />
            </SC.Image>
          )}
          <Container>
            <SC.Content>
              <AppTitle>{title}</AppTitle>
              <OptionsList list={optionsList} />
              <SC.ButtonsWrap>
                <Button path={APP_ROUTES.registration} type="outer-link">
                  {_t("get_started")}
                </Button>
                <Button onClick={() => history.goBack()} isBorder>
                  {_t("Back")}
                </Button>
              </SC.ButtonsWrap>
            </SC.Content>
          </Container>
        </SC.Wrap>
      </DefaultPage>
    </AppTheme>
  );
};

export default CommissionPage;
