import React, { useEffect } from "react";
import { DefaultPage } from "layouts/outer/DefaultPage";
import {
  MainSlider,
  MainMapCards,
  OurBenefits,
  HowItWorks,
  Commissions,
  AboutUs,
} from "pages/outer/sections";
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/");
  }, []);
  return (
    <DefaultPage hasBackground={false}>
      <MainSlider />
      <MainMapCards />
      <OurBenefits />
      <HowItWorks />
      <Commissions />
      <AboutUs />
    </DefaultPage>
  );
};

export default Home;
