import styled from "styled-components";
import { Link } from "react-router-dom";

export const Logo = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: ${(p) => (p.$isBigLogo ? "160px" : "134px")};
  position: relative;
  z-index: 99;
`;
